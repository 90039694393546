import { DesignationGroupId } from '../autogenerated/api.generated.clients';

const routes = [
  {
    path: '/members',
    name: 'members',
    redirect: { name: 'members-dashboard' },
    components: {
      content: () => import('../views/Members.vue'),
    },
    meta: {
      title: 'Members',
      PageH1: 'Members',
      requiresAuth: true,
      accessObject: [{
        designations: [DesignationGroupId.MemberView],
      }],
    },
    children: [{
      path: '/members/dashboard',
      name: 'members-dashboard',
      components: {
        membersContent: () => import('@components/partials/members/MemberDashboard.vue'),
      },
      meta: {
        title: 'Members Dashboard',
        PageH1: 'Members',
        requiresAuth: true,
        accessObject: [{
          designations: [DesignationGroupId.MemberView],
        }],
      },
    },
   ],
  },
  {
    path: '/members/:memberId',
    name: 'individual-member',
    components: {
      content: () => import('../views/MemberDetails.vue'),
    },
    props: true,
    meta: {
      title: 'Member Details',
      requiresAuth: true,
      accessObject: [{
        designations: [DesignationGroupId.MemberView],
      }],
    },
  },
];

export default routes;
