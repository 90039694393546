// ##################### MSAL Config Details - Start ##################### //
// This configuration is used by the /services/authenticate.js file
// to authenticate against Azure AD
//
// Variables are currently pulled from the env file; however, these will
// need to be shifted to secrets manager since they really shouldn't be
// stored with the code in Gitub
// ##################### MSAL Config Details - End ##################### //
import { LogLevel } from '@azure/msal-browser';
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { logEvent } from '../helpers/logging';

// Add scopes for id token
export async function scopes() {
  const applicationSettingsStore = useApplicationSettingsStore();
  return [
    applicationSettingsStore.appAuth.authScope,
  ];
};

export async function graphScopes() {
  const applicationSettingsStore = useApplicationSettingsStore();
  return [
    applicationSettingsStore.appAuth.authScopeGraph,
  ];
};

// Config is used to set values for auth call
// Client Id is the Client Id of the App in Azure
// Authority is the Microsoft Online URL with Tenant Id
// Redirect URI is the redirect uri configured in Azure
// Cache - the cacheLocation must be set to 'localStorage'
// in order for users to be authenticated between tabs
// We have it set to sessionStorage for added security
// this way the details clear when the tab is closed and does not persist
// More details on MSAL config can be found at the following link:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export async function config() {
  const applicationSettingsStore = useApplicationSettingsStore();
  return {
    auth: {
      clientId: applicationSettingsStore.appAuth.authClientId,
      authority: applicationSettingsStore.appAuth.authAuthority,
      knownAuthorities: [
        applicationSettingsStore.appAuth.authAuthority,
      ],
      redirectUri: applicationSettingsStore.appAuth.authRedirect,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        loggerCallback,
      },
    },
    // Cache details on caching and cach configurations can be found at the following URLs
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md#cache-config-options
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
      // cacheLocation: 'sessionStorage',
    },
  };
};

// The level of logging can be configured here for Azure Auth Activity
// This could write out certain level types to Sentry as well
// More details on logging can be found at the following link
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logging.md
function loggerCallback (level, message, containsPii) {
  if (!containsPii) {
    const parts = message.split(' : ');
    const text = parts.pop();
    let logObject;
    switch (level) {
      case LogLevel.Error:
        logObject = {
          type: 'error',
          message: text,
          fileName: 'msalConfig.js',
          functionName: 'loggerCallback',
        };
        logEvent(logObject);
        return;

      case LogLevel.Warning:
        logObject = {
          type: 'warning',
          message: text,
          fileName: 'msalConfig.js',
          functionName: 'loggerCallback',
        };
        logEvent(logObject);
        return;
      // case LogLevel.Info:
      //   return console.info(text);

      // case LogLevel.Verbose:
      //   return console.debug(text);
    }
  }
}
