import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import {
  loadUser,
  clearUser,
  getAllActiveCompassUsers,
  getAllUsersAzure,
  getUserPhoto,
  getNavigationLinks,
  createNewCorrelationId,
  getDashboardUsers,
  createCompassUser,
  getAppealAuditUsers,
  getAppealSpecialistUsers,
  getAllMemberAdvocateUsers,
  getUsers,
} from '../../services/user';
import { logEvent } from '../../helpers/logging';
import { useAuthenticateStore } from '../pinia/authenticate';

export const useUserStore = defineStore('user', {
  state: () => ({
    users: [],
    _userDetails: {
      userId: null,
      userName: '',
      userPhoto: null,
      fullName: '',
      emailAddress: '',
      azureObjectId: '',
      userAccess: {
        designationGroupIds: [],
        userTypeId: null,
        serviceCoordinatorIds: [],
        assignedToIds: [],
        hrLiaisonIds: [],
        implementationManagerIds: [],
        implementationCoordinatorIds: [],
      },
    },
    _userPreferences: (() => {
      try {
        const authenticateStore = useAuthenticateStore();
        return authenticateStore.localStorageObject.userPreferences;
      } catch (error) {
        return {};
      }
    })(),
    _appName: 'Compass',
    _correlationId: (() => {
      try {
        const authenticateStore = useAuthenticateStore();
        return authenticateStore.localStorageObject.correlationId;
      } catch (error) {
        return null;
      }
    })(),
  }),
  getters: {
    userDetails: (state) => state._userDetails,
    userPreferences(state) {
      let userPreferences = state._userPreferences;
      if (userPreferences === null || userPreferences === undefined || Object.keys(userPreferences).length === 0) {
        const authenticateStore = useAuthenticateStore();
        userPreferences = authenticateStore.localStorageObject.userPreferences;
      }
      return userPreferences;
    },
    appName: (state) => state._appName,
    correlationId(state) {
      let correlationId = state._correlationId;
      if (correlationId === null || correlationId === undefined) {
        const authenticateStore = useAuthenticateStore();
        correlationId = authenticateStore.localStorageObject.correlationId;
      }
      Sentry.setTag('correlationId', correlationId);
      return correlationId;
    },
    hasPermission: (state) => (permissionId) => state._userDetails.userAccess.designationGroupIds.includes(permissionId), 
  },
  actions: {
    async loadUser(event) {
      return loadUser(event)
        .then((response) => {
          if (response) {
            logEvent({
              type: 'verbose',
              message: `User Retrieved: ${JSON.stringify(response)}`,
              functionName: 'actions/loadUser',
              fileName: 'store/pinia/user.js',
            });
            this._userDetails.userName = response.userName;
            this._userDetails.userId = response.userId;
            this._userDetails.fullName = response.fullName;
            this._userDetails.azureObjectId = response.azureObjectId;
            this._userDetails.emailAddress = response.emailAddress;
            this._userDetails.userAccess.userTypeId = response.userTypeId;
            this._userDetails.userAccess.designationGroupIds = response.designationGroupIds;
            this._userDetails.userAccess.serviceCoordinatorIds = response.serviceCoordinatorIds;
            this._userDetails.userAccess.assignedToIds = response.assignedToIds;
            this._userDetails.userAccess.hrLiaisonIds = response.hrLiaisonIds;
            this._userDetails.userAccess.implementationManagerIds = response.implementationManagerIds;
            this._userDetails.userAccess.implementationCoordinatorIds = response.implementationCoordinatorIds;
          } else {
            // Undefined means we have invalid auth
            // Need to do something here
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    async fetchUsers() {
      try {
        // get all users from the user service
        const response = await getAllActiveCompassUsers();
        // log
        const usersData = await response;
        if (usersData) {
          this.users = usersData.data;
        } else {
          // Handle server errors or invalid responses
            const logObject = {
                type: 'error',
                message: `Failed to fetch users`,
                fileName: 'user.js',
                functionName: 'fetchUsers',
            };
            logEvent(logObject);
        }
        return usersData.data;
      } catch (error) {
        // Handle errors in fetching data
          const logObject = {
              type: 'error',
              message: `Failed to fetch users`,
              fileName: 'user.js',
              functionName: 'fetchUsers',
          };
          logEvent(logObject);
      }
    },
    async clearUser(event) {
      return clearUser(event)
        .then((response) => {
          if (response) {
            this._userDetails.userName = response.userName;
            this._userDetails.userId = response.userId;
            this._userDetails.fullName = response.fullName;
            this._userDetails.azureObjectId = response.azureObjectId;
            this._userDetails.emailAddress = response.emailAddress;
            this._userDetails.userTypeId = response.userTypeId;
            this._userDetails.designationGroupIds = response.designationGroupIds;
            this._userDetails.serviceCoordinatorIds = response.serviceCoordinatorIds;
            this._userDetails.assignedToIds = response.assignedToIds;
            this._userDetails.hrLiaisonIds = response.hrLiaisonIds;
            this._userDetails.implementationManagerIds = response.implementationManagerIds;
            this._userDetails.implementationCoordinatorIds = response.implementationCoordinatorIds;
            localStorage.clear();
            sessionStorage.clear();
          } else {
            // Logout failed, so display message to user
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    async getAppealSpecialistUsers(event) {
      return getAppealSpecialistUsers(event);
    },
    async getAppealAuditUsers(event) {
      return getAppealAuditUsers(event);
    },
    async createCompassUser(payload) {
      return createCompassUser(payload);
    },
    async getAllActiveCompassUsers() {
      return getAllActiveCompassUsers()
        .then((response) => {
          if (response) {
            return response;
          } else {
            return [];
          }
        });
    },
    async getAllUsersAzure(event) {
      return getAllUsersAzure(event);
    },
    async getDashboardUsers(event) {
      return getDashboardUsers(event);
    },
    async getUserPhoto(event) {
      return getUserPhoto(event)
        .then((response) => {
          if (response) {
              this._userDetails.userPhoto = response.userPhoto || '';
          } else {
            // Unable to get the user photo
          }
        })
        .catch((e) => {
            const logObject = {
                type: 'error',
                message: `Error getting user photo: ${e.message}`,
                fileName: 'user.js',
                functionName: 'getUserPhoto',
            };
            logEvent(logObject);
          this._userDetails.userPhoto = '';
        });
    },
    async getNavigationLinks() {
      return getNavigationLinks(this._userDetails.userAccess)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return [];
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    async createNewCorrelationId(event) {
      return createNewCorrelationId(event)
        .then((response) => {
          if (response) {
            this.setCorrelationId(response.correlationId);
            Sentry.setTag('correlationId', response.correlationId);
          } else {
            return [];
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    async updateLeftNav(event) {
      this.setUserPreferencesLeftNav(event);
    },
    async setUserPreferencesLeftNav(event) {
      logEvent({
        type: 'verbose',
        message: `userPreferences->leftNav Toggled, ${event}`,
        functionName: 'SET_USER_PREFERENCES_LEFT_NAV',
        fileName: 'store/pinia/user.js',
      });
      const authenticateStore = useAuthenticateStore();
      this._userPreferences.leftNav = event;
      const storageObject = authenticateStore.localStorageObject;
      storageObject.userPreferences.leftNav = event;
      authenticateStore.setLocalStorageObject(storageObject);
    },
    async setCorrelationId(event) {
      logEvent({
        type: 'verbose',
        message: `correlationId Created, ${event}`,
        functionName: 'SET_CORRELATION_ID',
        fileName: 'store/pinia/user.js',
      });
      const authenticateStore = useAuthenticateStore();
      this._correlationId = event;
      const storageObject = authenticateStore.localStorageObject;
      storageObject.correlationId = event;
      authenticateStore.setLocalStorageObject(storageObject);
    },
    async getAllMemberAdvocateUsers() {
      return getAllMemberAdvocateUsers()
        .then((response) => {
          if (response) {
            return response.data;
          } else {
            return [];
          }
        });
    },
    async getUsers(params) {
      return getUsers(params)
        .then((response) => {
          if (response) {
            return response.data;
          } else {
            return [];
          }
        });
    },
  },
});
