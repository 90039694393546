const routes = [
  {
    path: '/appeals',
    name: 'appeals',
    redirect: { name: 'appeals-dashboard'},
    beforeEnter() {
      // const applicationSettingsStore = useApplicationSettingsStore();
      // location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/nominations');
    },
    components: {
      content: () => import('../views/Appeals.vue'),
    },
    meta: {
      requiresAuth: true,
      accessObject: [
        {
          accessTypeFunction: null,
          designations: ['Appeals'],
        },
      ],
    },
    children: [
    {
      path: '/appeals/dashboard',
      name: 'appeals-dashboard',
      components: {
        appealsContent: () => import('@components/partials/appeals/AppealsDashboard.vue'),
      },
      meta: {
        title: 'Appeals Dashboard',
        pageH1: 'Appeals',
        requiresAuth: true,
        accessObject: [
          {
            accessTypeFunction: null,
            designations: ['Auditor', 'Legal', 'AppealsOne', 'AppealsTwo'],
          },
        ],
      },
    },
    {
      path: ':id',
      name: 'individual-appeal',
      
      components: {
        appealsContent: () => import('@components/partials/appeals/AppealDetail.vue'),
      },
      meta: {
        requiresAuth: true,
        pageH1: 'Appeal Detail',
        accessObject: [
          {
            accessTypeFunction: null,
            designations: ['Auditor', 'Legal', 'AppealsOne', 'AppealsTwo'],
          },
        ],
      },
    }],
  },
];

export default routes;
