
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';

const routes = [
  {
    path: '/nominations',
    name: 'nominations',
    redirect: { name: 'nominations-dashboard' },
    components: {
      content: () => import('../views/Nominations.vue'),
    },
    meta: {
      title: 'Nominations',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
      pageH1: 'Nominations',
    },
    children: [
      {
        path: '/nominations/dashboard',
        name: 'nominations-dashboard',
        components: {
          nomContent: () => import('@components/partials/nominations/NominationsDashboard.vue'),
        },
        meta: {
          title: 'Nominations Dashboard',
          pageH1: 'Nominations Dashboard',
          requiresAuth: true,

        },
      },
      {
        path: '/reconcile',
        name: 'nominations-to-reconcile',
        beforeEnter() {
          const applicationSettingsStore = useApplicationSettingsStore();
          location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/reconcile`);
        },
      },
    ],
  },
  {
    path: '/nominations/:nominationId',
    name: 'individual-nomination',
    components: {
      content: () => import('../views/NominationDetails.vue'),
    },
    props: (route) => ({ nominationId: route.params.nominationId }),
    meta: {
      title: 'Nominations',
      requiresAuth: true,
      accessObject: null,
      pageH1: 'Nomination Details',
    },
  },
];

export default routes;
