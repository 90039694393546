const routes = [
  {
    path: '/accountmanagers',
    name: 'accountmanagers',
    redirect: { name: 'amAm' },
    components: {
      content: () => import('../views/AccountManagers.vue'),
    },
    meta: {
      title: 'AM Dashboard',
      requiresAuth: true,
      accessObject: [
        {
          accessTypeFunction: 'isAtLeastServiceCoordinator',
          designations: null,
        },
      ],
    },
    children: [
      {
        path: 'am',
        name: 'amAm',
        components: {
          amAmContent: () => import('@components/partials/accountmanagers/AMTableStats.vue'),
        },
        meta: {
          title: 'AM Dashboard',
          requiresAuth: true,
          accessObject: [
            {
              accessTypeFunction: 'isAtLeastServiceCoordinator',
              designations: null,
            },
          ],
        },
      },
      {
        path: 'planchoices',
        name: 'amPlanChoices',
        redirect: { name: 'planchoices-all' },
        components: {
          amTpaContent: () => import('../components/partials/accountmanagers/AMPlanChoices.vue'),
        },
        meta: {
          title: 'Account Managers - Plan Choices',
          requiresAuth: true,
        },
        children: [
          {
            path: 'all',
            name: 'planchoices-all',
            components: {
              amTpaSubContent: () => import('../components/partials/accountmanagers/AMVendorPartner.vue'),
            },
            meta: {
              title: 'Account Managers - Create',
              requiresAuth: true,
              accessObject: [
                {
                  accessTypeFunction: 'isAtLeastServiceCoordinator',
                  designations: null,
                },
              ],
            },
          },
          {
            path: 'create',
            name: 'planchoices-create',
            components: {
              amTpaSubContent: () => import('../components/partials/accountmanagers/AMCreatePlanChoice.vue'),
            },
            meta: {
              title: 'Account Managers - Create',
              requiresAuth: true,
              accessObject: [
                {
                  accessTypeFunction: 'isAtLeastServiceCoordinator',
                  designations: null,
                },
              ],
            },
          },
          {
            path: ':id',
            name: 'planchoices-individual',
            components: {
              amTpaSubContent: () => import('../components/partials/accountmanagers/AMEditPlanChoice.vue'),
            },
            meta: {
              title: 'Account Managers - Edit',
              requiresAuth: true,
              accessObject: [
                {
                  accessTypeFunction: 'isAtLeastServiceCoordinator',
                  designations: null,
                },
              ],
            },
          },
        ],
      },
      {
        path: 'products',
        name: 'amProducts',
        components: {
          amProductsContent: () => import('@components/partials/accountmanagers/AMProducts.vue'),
        },
        meta: {
          title: 'Account Managers - Products',
          requiresAuth: true,
          accessObject: [
            {
              accessTypeFunction: 'isAtLeastServiceCoordinator',
              designations: null,
            },
          ],
        },
      },
    ],
  },
];

export default routes;
