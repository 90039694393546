<template>
  <div
    :class="[block ? 'block' : 'inline-block']"
  >
    <v-btn
      v-if="!block || block === false"
      :disabled="disabled"
      :loading="loading"
      :color="buttonColor"
      :size="size"
      :ref="label"
      :height="height"
      :variant="variant"
      :type="type"
      @click="buttonClick(label)"
    >
      <template #append>
        <v-icon
          v-if="appendIcon"
          :icon="appendIcon"
        ></v-icon>
      </template>
      <template #prepend>
        <v-icon
          v-if="prependIcon"
          :icon="prependIcon"
        ></v-icon>
      </template>
      {{ label }}
    </v-btn>
    <v-btn
      v-else
      :disabled="disabled"
      :loading="loading"
      :color="buttonColor"
      block
      :size="size"
      :ref="label"
      :height="height"
      :variant="variant"
      :type="type"
      @click="buttonClick(label)"
    >
      <template #append>
        <v-icon
          v-if="appendIcon"
          :icon="appendIcon"
        ></v-icon>
      </template>
      <template #prepend>
        <v-icon
          v-if="prependIcon"
          :icon="prependIcon"
        ></v-icon>
      </template>
      {{ label }}
    </v-btn>
  </div>
</template>
<script>
import { ref, computed } from 'vue';

export default {
  name: 'CustomButton',
  props: {
    label: { type: String, required: true },
    loading: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'default' },
    btnType: { type: String, required: false, default: 'primary'},
    height: { type: String, required: false },
    appendIcon: { type: String, required: false },
    prependIcon: { type: String, required: false },
    block: { type: Boolean, required: false, default: false},
    variant: { type: String, required: false, default: 'elevated' },
    type: { type: String, required: false, default: 'button' },
  },

  setup(props, { emit }) {
    // eslint-disable-next-line no-unused-vars
    
    const componentName = ref('CustomButton.vue');

    function buttonClick() {
      emit('update:buttonClick');
    }

    const buttonColor = computed(() => {
      if (props.btnType === 'primary') {
        return 'blue';
      } else if (props.btnType === 'secondary') {
        return 'grey-lighten-3';
      } else {
        return 'blue';
      }
    });

    return {
      componentName,
      buttonClick,
      buttonColor,
    };
  },
};
</script>

<style scoped>
</style>
