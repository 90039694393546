import { useMasqueradeStore } from '../store/pinia/masquerade';
import { useUserStore } from '../store/pinia/user';
import { logEvent } from '../helpers/logging';
import { DesignationGroupId } from '@/autogenerated/api.generated.clients.ts';

  // Get the authorization details for the working user
  export async function getAuthorizationDetails() {
    const masqueradeStore = useMasqueradeStore();
    if (masqueradeStore.masqueradeId) {
      // console.log('masquerading');
      // Currently masquerading, so we'll return the masquerade user details
      return masqueradeStore.userDetails;
    } else {
      // Not masquerading, so we'll return the current user details
      // console.log('not masquerading');
      const userStore = useUserStore();
      return userStore.userDetails;
    }
  }

  export function logAccessDenied(userName, userId, pageName) {
    logEvent({
      type: 'warning',
      message: `User ${userName} (${userId}) was blocked from accessing page ${pageName}`,
      functionName: 'doesHaveAccessToPage',
      fileName: 'auth/authorization.js',
    });
  }

  // Checks to see if the user has access to the page they are attempting to view
  // accessObject contains the specific function name for who should have access along with any designations
  export async function doesHaveAccessToPage(pageName, accessObject) {
    try {
      // Get the user's details
      const userDetails = await getAuthorizationDetails();
      const { userName, userId, userAccess } = userDetails;
      const { designationGroupIds } = userAccess;
  
      if (!accessObject) {
        return true; // If accessObject is null or undefined, grant access
      }
  
      // Process each accessItem to check for access
      const accessResults = await Promise.all(accessObject.map(async (accessItem) => {
        // If accessTypeFunction is null, check for designation matches, otherwise grant access
        if (accessItem.accessTypeFunction === null || accessItem.accessTypeFunction === undefined) {
          if (accessItem.designations) {
                        return accessItem.designations.some(designation => designationGroupIds.includes(designation));
          }
          return true; // Grant access if no designations are specified
        }
  
        // Process accessTypeFunction when not null
        if (functionsMap[accessItem.accessTypeFunction]) {
          const userTypeAccess = await functionsMap[accessItem.accessTypeFunction]();
          if (userTypeAccess) {
            if (accessItem.designations) {
              return accessItem.designations.some(designation => designationGroupIds.includes(designation));
            }
            return true; // Grant access if userTypeAccess is true and no further designations are specified
          }
        }
        return false; // Deny access by default
      }));
  
      // Determine final access based on individual checks
      // console.log('accessResults', accessResults);
      const hasAccess = accessResults.some(result => result);
      // console.log('hasAccess', hasAccess);
      if (!hasAccess) {
        logAccessDenied(userName, userId, pageName);
      }
      return hasAccess;
    } catch (error) {
      // Handle errors and log access denied
      const { userName, userId } = await getAuthorizationDetails();
      logAccessDenied(userName, userId, pageName);
      return false; // Deny access in case of error
    }
  }  

  // Checks whether the user has access to the feature they're attempting to use or view
  // featureType: 'button', 'link', 'tab', 'menu', 'other'
  export async function doesHaveAccessToFeature(featureName, featureType) {
    let hasAccess = false;  
    try {
      const userDetails = await getAuthorizationDetails();
      const userDesignations = userDetails.userAccess.designationGroupIds;
      // Nomination Dashboard Features
      if (featureName === 'nominations/dashboard/reconcile' && featureType === 'link') {
        // Must be atLeastServiceCoordinator or Designation of 12
        if (isAtLeastServiceCoordinator() === true || userDesignations.indexOf(12) !== -1) {
          hasAccess = true;
        }
      } else if (featureName === 'nominations/dashboard/excelExport' && featureType === 'button') {
        // Must have NominationDashboardExcelExport designation group
        if (userDesignations.includes(DesignationGroupId.NominationDashboardExcelExport)) {
          hasAccess = true;
        }
      } else if (featureName === 'doctors/excelExport' && featureType === 'button') {
        // We are currently making this available to all users
        hasAccess = true;
      } else if (featureName === 'facilities/excelExport' && featureType === 'button') {
        // We are currently making this available to all users
        hasAccess = true;
      } else if (featureName === 'doctors/bulkUpdate' && featureType === 'button') {
        // User must have designation 28 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.AgreementStatusBulkEditTool)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'facilities/bulkUpdate' && featureType === 'button') {
        // User must have designation 28 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.AgreementStatusBulkEditTool)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'members/view' && featureType === 'link') {
        // User must have designation 55 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'members/merge' && featureType === 'button') {
        // User must have designation 50 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberMerge)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'members/archive' && featureType === 'button') {
        // User must have designation 51 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberArchive)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'members/create' && featureType === 'link') {
        // User must have designation 51 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberCreate)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/pinned/general' && featureType === 'checkbox') {
        // User must have designation 48 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteGeneralPin)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/pinned/incident' && featureType === 'checkbox') {
        // User must have designation 49 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteIncidentPin)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/edit' && featureType === 'link') {
        // User must have designation 75 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteEdit)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/archive' && featureType === 'link') {
        // User must have designation 76 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteArchive)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/delete' && featureType === 'link') {
        // User must have designation 77 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteDelete)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'membernotes/create' && featureType === 'link') {
        // User must have designation 78 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberNoteCreate)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      }  else if (featureName === 'members/delete' && featureType === 'button') {
        // User must have designation 52 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.MemberDelete)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'client/profile' && featureType === 'link') {
        // User must have designation 73 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.ClientProfileView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'client/information' && featureType === 'link') {
        // User must have designation 74 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.ClientInformationView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patient/view' && featureType === 'link') {
        // User must have designation 64 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patient/add' && featureType === 'button') {
        // User must have designation 68 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientAdd)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patient/delete' && featureType === 'button') {
        // User must have designation "PatientDelete" to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientDelete)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patient/edit' && featureType === 'button') {
        // User must have designation 65 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientEdit)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patient/archive' && featureType === 'button') {
        // User must have designation 67 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientArchive)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'provider/information' && featureType === 'link') {
        // User must have designation 80 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.ProviderInfoView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'facility/information' && featureType === 'link') {
        // User must have designation 81 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.FacilityInfoView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patientnotes/pinned/general' && featureType === 'checkbox') {
        // User must have designation 48 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientNotePin)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patientnotes/edit' && featureType === 'link') {
        // User must have designation 75 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientNoteEdit)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patientnotes/archive' && featureType === 'link') {
        // User must have designation 76 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientNoteArchive)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patientnotes/delete' && featureType === 'link') {
        // User must have designation 77 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientNoteDelete)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'patientnotes/create' && featureType === 'link') {
        // User must have designation 79 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.PatientNoteCreate)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nomination/details/view' && featureType === 'link') {
        // User must have designation 82 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationDetailsView)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      }  else if (featureName === 'nominationnotes/pinned/general' && featureType === 'checkbox') {
        // User must have designation 48 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteGeneralPin)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nominationnotes/pinned/contracting' && featureType === 'checkbox') {
        // User must have designation 49 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteContractingPin)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nominationnotes/edit' && featureType === 'link') {
        // User must have designation 75 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteEdit)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nominationnotes/archive' && featureType === 'link') {
        // User must have designation 76 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteArchive)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nominationnotes/delete' && featureType === 'link') {
        // User must have designation 77 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteDelete)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      } else if (featureName === 'nominationnotes/create' && featureType === 'link') {
        // User must have designation 78 to have accesss to this button
        if (userDesignations.includes(DesignationGroupId.NominationNoteCreate)) {
          hasAccess = true;
        } else {
          hasAccess = false;
        }
      }
    } catch (error) {
      const userName = await getAuthorizationDetails().userName;
      const userId = await getAuthorizationDetails().userId;
      logEvent({
        type: 'warning',
        message: `User ${userName} (${userId}) was blocked from viewing a ${featureType} for feature ${featureName}`,
        functionName: 'doesHaveAccessToFeature',
        fileName: 'auth/authorization.js',
      });
      hasAccess = false;
    }
    return hasAccess;
  }

  export async function getUserTypeId() {
    const userDetails = await getAuthorizationDetails();
    return userDetails.userAccess.userTypeId;
  }

  export async function isAdmin() {
    const validUserTypes = ['Administrator'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  export async function isAtLeastAM() {
    const validUserTypes = ['Administrator', 'AccountManager', 'ProviderRelationsOne', 'Executive', 'BalanceBillOne'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  export async function isAtLeastLeadership() {
    const validUserTypes = ['Administrator', 'Executive'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  export async function isOnBBTeam() {
    const validUserTypes = ['BalanceBill', 'BalanceBillOne', 'BalanceBillTwo'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  export async function isAtLeastAccountManager() {
    const validUserTypes = ['AccountManager', 'ProviderRelationsOne', 'BalanceBillOne'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId) || await isAtLeastLeadership();
  }

  export async function isAtLeastMemberAdvocate() {
    const validUserTypes = ['MemberAdvocate'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId) || await isAtLeastServiceCoordinator();
  }

  export async function isAtLeastProviderRelations() {
    const validUserTypes = ['ProviderRelations'];
    const userTypeId = await getUserTypeId();
    return isAtLeastAccountManager() || isOnBBTeam() || validUserTypes.some((type) => type === userTypeId);
  }

  export async function isPrOrLeadership() {
    const validUserTypes = ['ProviderRelations'];
    const userTypeId = await getUserTypeId();
    return isAtLeastLeadership() || validUserTypes.some((type) => type === userTypeId);
  }

  export async function isAtLeastServiceCoordinator() {
    const validUserTypes = ['ServiceCoordinator'];
    const userTypeId = await getUserTypeId();

    // console.log(userTypeId);
    // console.log(validUserTypes.some((type) => type === userTypeId) || await isAtLeastAccountManager());

    return validUserTypes.some((type) => type === userTypeId) || await isAtLeastAccountManager();
  }

  export async function doesHaveAppealsAccess() {
    const validUserTypes = ['Appeals'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  // Currently following logic behind returning MA List in UserController based on these user types
  // May need to change in the future if we want to go permission based
  export async function doesHaveMaAssignmentAccess() {
    const validUserTypes = ['AccountManager', 'ServiceCoordinator', 'Administrator'];
    const userTypeId = await getUserTypeId();
    return validUserTypes.some((type) => type === userTypeId);
  }

  export async function isAssignedToClient(clientId) {
    const userDetails = await getAuthorizationDetails();

    // Check if the user is assigned as Service Coordinator
    if(userDetails.userAccess.serviceCoordinatorIds.includes(clientId)) {
      return true;
    }
    // Check if the user is assigned as Account Manager
    if(userDetails.userAccess.assignedToIds.includes(clientId)) {
      return true;
    }
    // Check if the user is assigned as HR Liason
    if(userDetails.userAccess.hrLiaisonIds.includes(clientId)) {
      return true;
    }
    // Check if the user is assigned as Implementation Manager
    if(userDetails.userAccess.implementationManagerIds.includes(clientId)) {
      return true;
    }

    // Check if the user is assigned as Implementation Coordinator and Client is InImplementation
    // This is a double check because implementationCoordinatorIds isnt populated unless the user is an Implementation Coordinator
    if (userDetails.userAccess.designationGroupIds.includes(DesignationGroupId.ImplementationCoordinator)) {
      if(userDetails.userAccess.implementationCoordinatorIds.includes(clientId)) {
        return true;
      }
    }
    
    return false;
  }

  // This is used by the doesHaveAccessToPage function
  // to determine which function to call to check if they have the access type required to view the page
  const functionsMap = {
    'isAdmin': isAdmin,
    'isAtLeastAM': isAtLeastAM,
    'isAtLeastLeadership': isAtLeastLeadership,
    'isOnBBTeam': isOnBBTeam,
    'isAtLeastAccountManager': isAtLeastAccountManager,
    'isAtLeastMemberAdvocate': isAtLeastMemberAdvocate,
    'isAtLeastProviderRelations': isAtLeastProviderRelations,
    'isPrOrLeadership': isPrOrLeadership,
    'isAtLeastServiceCoordinator': isAtLeastServiceCoordinator,
    'doesHaveAppealsAccess': doesHaveAppealsAccess,
  };

  export default {
    doesHaveAccessToPage,
    doesHaveAccessToFeature,
    isAdmin,
    isAtLeastServiceCoordinator,
    isAtLeastAM,
    isAtLeastLeadership,
    isOnBBTeam,
    isAtLeastAccountManager,
    isAtLeastMemberAdvocate,
    isAtLeastProviderRelations,
    isPrOrLeadership,
    doesHaveAppealsAccess,
  };