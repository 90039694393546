import { DesignationGroupId } from '../autogenerated/api.generated.clients';

const routes = [
  {
    path: '/patients',
    name: 'patients',
    redirect: { name: 'patients-dashboard' },
    components: {
      content: () => import('../views/Patients.vue'),
    },
    meta: {
      title: 'Patients',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    children: [{
      path: '/patients/dashboard',
      name: 'patients-dashboard',
      components: {
        patientsContent: () => import('@components/partials/patient/PatientDashboard.vue'),
      },
      meta: {
        title: 'Patients Dashboard',
        PageH1: 'Patients',
        requiresAuth: true,
        accessObject: [{
          designations: [DesignationGroupId.PatientView],
        }],
      },
    },
   ],
  },
  {
    path: '/patients/:patientId',
    name: 'individual-patient',
    components: {
      content: () => import('../views/PatientDetails.vue'),
    },
    props: true,
    meta: {
      title: 'Patient Details',
      requiresAuth: true,
      accessObject: [{
        designations: [DesignationGroupId.PatientView],
      }],
    },
  },
];

export default routes;
