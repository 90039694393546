<template>
  <v-layout align-right>
    <v-menu>
      <template #activator="{ props }">
        <v-container class="pointer" v-bind="props">
          <v-avatar data-testid="avatar-image" v-show="photo !== '' && photo !== null && photo !== undefined" class="pointer" color="indigo" size="large">
            <img class="avatar-image" :src="`data:image/png;base64,${photo}`"  />
          </v-avatar>
          <span v-show="photo === '' || photo === undefined" class="pointer">
            {{ displayName }}
          </span>
          <span v-show="photo === null" class="pointer">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </v-container>
      </template>
      <v-list class="pull-right">
        <v-list-item v-for="(item, index) in items" :key="index" v-bind="item.linkProps" :class="(item.topBorder && index !== 0) ? 'topBorder' : ''">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-on:click="logoutUser()" class="topBorder">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-layout>
</template>


<script>
import { ref, watch, computed  } from 'vue';
import { useAuthenticateStore } from '@/store/pinia/authenticate';
import { useUserStore } from '@/store/pinia/user';
import { useMasqueradeStore } from '@/store/pinia/masquerade';
import { logEvent } from '@/helpers/logging';
import { isAtLeastServiceCoordinator, isAtLeastLeadership } from '@/auth/authorization';

export default {
  name: 'UserDropdown',
  props: {},
  setup() {
    const componentName = ref('UserDropdown.vue');
    const authenticateStore = useAuthenticateStore();
    const userStore = useUserStore();
    const masqueradeStore = useMasqueradeStore();
    const username = ref('');
    const photo = ref('');
    const fullname = ref('');
    const userAccess = ref({
      masqueradeId: undefined,
      originalUser: false,
      effectiveUserType: '',
      atLeastServiceCoordinator: false,
      atLeastLeadership: false,
    });
    const items = ref([]);

    function createLink(title, action, visible = false, topBorder = false) {
      return {
        title,
        action,
        topBorder,
        visible,
      };
    }

    async function createLinks() {
      const admin = userAccess.value.atLeastLeadership
        ? [createLink('Admin Settings', '/admin/settings', true)]
        : [];

      const accountManager = userAccess.value.atLeastServiceCoordinator
        ? [createLink('Account Manager Settings', '/accountmanagers/settings', true)]
        : [];

      return [...admin, ...accountManager];
    }

    async function getUserAccess() {
      try {
        userAccess.value.masqueradeId = masqueradeStore.masqueradeId;
        userAccess.value.originalUser = masqueradeStore.originalUser;
        userAccess.value.effectiveUserType = userStore.userDetails.userTypeId;
        userAccess.value.atLeastServiceCoordinator = await isAtLeastServiceCoordinator();
        userAccess.value.atLeastLeadership = await isAtLeastLeadership();
      } catch (error) {
        const logObject = {
          type: 'error',
          message: `${error.name}: ${error.message} - 2`,
          fileName: componentName.value,
          functionName: 'getLinks',
        };
        logEvent(logObject);
      }
    }

    function computeLinkProps(item) {
      const isExternalLink = url => /^((https?:)?\/\/)/.test(url);
      const linkProps = {
        [isExternalLink(item.action) ? 'href' : 'to']: item.action,
      };
      if (item.newTab) {
        linkProps.target = '_blank';
      }
      return linkProps;
    }

    async function getUserData() {
  const userObject = userStore.userDetails;
  fullname.value = userObject.fullName;
  username.value = userObject.userName;
  photo.value = userObject.userPhoto;

  // Get user access details
  await getUserAccess();

  items.value = await createLinks();
}

    async function logoutUser() {
      authenticateStore.logout()
        .then((response) => {
          const logObject = {
            type: 'verbose',
            message: `Successfully Logged Out: ${response}`,
            fileName: componentName.value,
            functionName: 'logoutUser',
          };
          logEvent(logObject);
        })
        .catch((error) => {
          const logObject = {
            type: 'error',
            message: `${error.name}: ${error.message}`,
            fileName: componentName.value,
            functionName: 'logoutUser',
          };
          logEvent(logObject);
        });
    }

    watch(
      () => userStore.userDetails,
      () => {
        getUserData();
      },
      {
        deep: true,
      },
    );

    return {
      items: computed(() => {
        return items.value.map(item => ({
          ...item,
          linkProps: computeLinkProps(item),
        }));
      }),
      fullname,
      photo,
      username,
      getUserData,
      logoutUser,
      userAccess,
    };
  },
  computed: {
    displayName() {
      if (
        this.userAccess.masqueradeId === '' ||
        this.userAccess.masqueradeId === null ||
        this.userAccess.masqueradeId === undefined
      ) {
        return this.username;
      }
      return `${this.username} as ${this.userAccess.masqueradeId}`;
    },
  },
  mounted() {
    this.getUserData();
  },
  methods: {},
};
</script>

<style scoped>
@import 'vuetify/dist/vuetify.min.css';

.pointer {
  cursor: pointer;
}

.topBorder {
  border: 0;
  border-top: 1px solid #eee;
}

.avatar-image {
  height: 48px;
  object-fit: cover;
}
</style>
